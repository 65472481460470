import { AdsPlacementDto } from 'types/dtos'
import {
  AdsPlacementModel,
  AdsPlacementOptionsModel,
  RtbPlacementConfigModel,
  VanPlacementConfigModel,
} from 'types/models'
import {
  RtbPlacementConfigDto,
  AdsPlacementOptionsDto,
  VanPlacementConfigDto,
  RoktCartItemDto,
} from 'types/dtos/ads'
import { AdKind } from '@marketplace-web/domain/ads'
import { GetRoktTransactionAttributesResp } from 'types/api'
import { RoktCartItem, RoktTransactionAttributes } from 'types/ads'

export const transformAdsPlacementOptions = (
  placementOptions: AdsPlacementOptionsDto,
): AdsPlacementOptionsModel => ({
  isSticky: placementOptions.is_sticky,
})

export const transformRtbPlacementConfig = (
  placementConfig: RtbPlacementConfigDto,
): RtbPlacementConfigModel => ({
  kind: placementConfig.kind,
  platform: placementConfig.platform,
  countryCode: placementConfig.country_code,
  shape: placementConfig.shape,
  page: placementConfig.page,
  dfpCode: placementConfig.dfp_code,
  dfpAccountId: placementConfig.dfp_account_id,
  options: transformAdsPlacementOptions(placementConfig.options),
  sizes: placementConfig.sizes,
  bids: placementConfig.bids,
  mediation: placementConfig.mediation,
})

export const transformVanPlacementConfig = (
  placementConfig: VanPlacementConfigDto,
): VanPlacementConfigModel => ({
  kind: placementConfig.kind,
  platform: placementConfig.platform,
  countryCode: placementConfig.country_code,
  shape: placementConfig.shape,
  page: placementConfig.page,
  options: transformAdsPlacementOptions(placementConfig.options),
  mediation: placementConfig.mediation,
  id: placementConfig.id,
  creative: {
    id: placementConfig.creative.id,
    size: placementConfig.creative.size,
    contentLink: placementConfig.creative.content_link,
  },
})

export const transformAdsPlacement = (placement: AdsPlacementDto): AdsPlacementModel => {
  if (placement.config.kind === AdKind.Van) {
    return transformVanPlacementConfig(placement.config)
  }

  return transformRtbPlacementConfig(placement.config)
}

export const transformAdsPlacements = (
  placements: Array<AdsPlacementDto>,
): Array<AdsPlacementModel> => placements.map(transformAdsPlacement)

export const transformRoktCartItem = (cartItem: RoktCartItemDto): RoktCartItem => ({
  minorCatID: cartItem.minor_cat_id,
  minorCat1: cartItem.minor_cat1,
})

export const transformRoktTransactionAttributes = ({
  billing_zip_code,
  amount,
  currency,
  cart_items,
  age,
}: GetRoktTransactionAttributesResp): RoktTransactionAttributes => ({
  billingzipcode: billing_zip_code,
  cartItems: cart_items.map(transformRoktCartItem),
  currency,
  amount,
  age,
})
