import { RtbPlacementConfigModel } from 'types/models'
import {
  AmazonTagFetchBidsConfig,
  BackBidsResponses,
  Bid,
  RtbAdPlacement,
  YieldbirdUnfilledRecoveryEventDetail,
} from 'types/ads'

import { CreatePlacementProps, GoogleCallbacks, RefreshManager, RequestManager } from './types'
import {
  AD_VISIBILITY_RATIO,
  ADS_BIDDER_TIMEOUT,
  Bidder,
  BidderState,
  USER_INACTIVE_TIME,
} from '../../constants'

const MOBILE_REGEX =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i

export const isMobile = (userAgent: string) => MOBILE_REGEX.test(userAgent)

export function isUserActive(refreshManager: RefreshManager) {
  const inactiveDuration = (Date.now() - refreshManager.lastActionTimestamp) / 1000

  return !document.hidden && inactiveDuration < USER_INACTIVE_TIME
}

export function setupGoogleEvents(googleSlot: googletag.Slot, callbacks: GoogleCallbacks) {
  const { googletag } = window

  if (!googletag) return

  Object.keys(callbacks).forEach(callbackKey => {
    const key = callbackKey as keyof googletag.events.EventTypeMap

    const callback = callbacks[key]

    if (!callback) return

    googletag.pubads().addEventListener(key, (event: googletag.events.Event) => {
      if (event.slot === googleSlot) callback(event)
    })
  })
}

export function handleUnfilledRecovery(event: Event) {
  const { detail } = event as CustomEvent<YieldbirdUnfilledRecoveryEventDetail>

  return (googleSlot: googletag.Slot, callbacks: GoogleCallbacks) => {
    const { slot, unfilledRecoveryAdUnit } = detail

    const googleSlotAdUnitPath = googleSlot.getAdUnitPath()

    const slotPathId = `${googleSlotAdUnitPath}#${googleSlot.getSlotElementId()}`

    if (slotPathId !== slot) return

    const yieldbirdRecoverySlot = googletag
      .pubads()
      .getSlots()
      .find(publicSlot => {
        return (
          publicSlot.getAdUnitPath() ===
          unfilledRecoveryAdUnit.substring(0, unfilledRecoveryAdUnit.indexOf('#'))
        )
      })

    if (!yieldbirdRecoverySlot) return

    setupGoogleEvents(yieldbirdRecoverySlot, callbacks)
  }
}

export function setupGoogleSlot(
  placement: RtbAdPlacement,
  placementId: string,
  callbacks: GoogleCallbacks,
  iframeTitle?: string,
): RtbAdPlacement {
  const { googletag } = window

  if (!googletag) return placement

  googletag.cmd.push(() => {
    const { adUnitPath, sizes } = placement

    if (iframeTitle) googletag.setAdIframeTitle(iframeTitle)

    const googleSlot = googletag.defineSlot(adUnitPath, sizes, placementId)

    if (!googleSlot) return placement

    googleSlot.addService(googletag.pubads())
    googleSlot.setCollapseEmptyDiv(true)
    setupGoogleEvents(googleSlot, callbacks)

    window.addEventListener('unfilledRecovery', (event: Event) =>
      handleUnfilledRecovery(event)(googleSlot, callbacks),
    )

    return {
      ...placement,
      googleSlot,
    }
  })

  return placement
}

export function getAdUnitPath({ dfpAccountId, dfpCode }: RtbPlacementConfigModel) {
  return `/${dfpAccountId}/${dfpCode}`
}

export function getAdUnitName({ shape, page, mediation }: RtbPlacementConfigModel) {
  const adUnitNameParts = ['ad', shape, page]

  if (mediation) {
    adUnitNameParts.push(mediation)
  }

  return adUnitNameParts.join('-')
}

export function isBidderStateValid(requestManager: RequestManager, bidder: Bidder) {
  return requestManager[bidder] === BidderState.Fetched
}

export function sendAdserverRequest(
  placement: RtbAdPlacement,
  placementId: string,
  requestManager: RequestManager,
) {
  const { googletag, pbjs, apstag } = window

  const { isManuallyRendered } = placement

  googletag.cmd.push(() => {
    const placementSlot = googletag
      .pubads()
      .getSlots()
      .find(slot => slot.getSlotElementId() === placementId)

    if (isBidderStateValid(requestManager, Bidder.Prebid))
      pbjs.setTargetingForGPTAsync([placementId])

    if (isBidderStateValid(requestManager, Bidder.Amazon)) apstag.setDisplayBids()

    if (isManuallyRendered) googletag.display(placementId)

    if (placementSlot && !isManuallyRendered) googletag.pubads().refresh([placementSlot])
  })
}

export function isArrayOfNumbers(candidate: unknown): candidate is Array<number | Array<number>> {
  return (
    !!candidate &&
    Array.isArray(candidate) &&
    !candidate.find(candidateItem => {
      return Array.isArray(candidateItem)
        ? !!candidateItem.find(candidateItemItem =>
            Number.isNaN(Number.parseInt(candidateItemItem, 10)),
          )
        : Number.isNaN(Number.parseInt(candidateItem, 10))
    })
  )
}

export function fetchAmazonBids(
  placement: RtbAdPlacement,
  placementId: string,
  onBidderBack: (bidderName: Bidder.Amazon, placementId: string, state: BidderState) => void,
  isRefresh = false,
) {
  const { apstag } = window
  let hasTimedOut = false

  if (!apstag) return

  const { sizes, dfpCode } = placement

  if (sizes === 'fluid' || !isArrayOfNumbers(sizes)) {
    onBidderBack(Bidder.Amazon, placementId, BidderState.BadSizes)

    return
  }

  const config: AmazonTagFetchBidsConfig = {
    slots: [
      {
        slotID: placementId,
        slotName: dfpCode,
        sizes,
      },
    ],
    params: {
      adRefresh: isRefresh ? '1' : '0',
    },
  }

  const timeout = setTimeout(() => {
    hasTimedOut = true

    onBidderBack(Bidder.Amazon, placementId, BidderState.TimedOut)
  }, ADS_BIDDER_TIMEOUT)

  apstag.fetchBids(config, () => {
    if (hasTimedOut) return

    clearTimeout(timeout)
    onBidderBack(Bidder.Amazon, placementId, BidderState.Fetched)
  })
}

export function fetchPrebidBids(
  placement: RtbAdPlacement,
  placementId: string,
  onBidderBack: (bidderName: Bidder.Prebid, placementId: string, state: BidderState) => void,
  isRefresh = false,
) {
  const { pbjs } = window

  if (!pbjs) return

  if (!placement?.bids?.length) {
    onBidderBack(Bidder.Prebid, placementId, BidderState.NoBids)

    return
  }

  const { sizes, bids, adUnitName, adUnitPath, googleSlot } = placement
  const adagioBidParams = bids.find(bid => bid.bidder === 'adagio')?.params

  const prebidPlacement = {
    code: placementId,
    mediaTypes: {
      banner: {
        sizes,
      },
    },
    bids: bids.reduce<Array<Bid>>((acc, bid) => {
      const stringifiedBid = JSON.stringify(bid)
      const stringifiedBids = JSON.stringify(acc)

      if (stringifiedBids.includes(stringifiedBid)) return acc

      return [...acc, bid]
    }, []),
    ortb2Imp: adagioBidParams
      ? {
          ext: {
            data: {
              ...adagioBidParams,
            },
          },
        }
      : undefined,
    pubstack: {
      adUnitPath,
      adUnitName,
    },
  }

  pbjs.que.push(() => {
    if (!isRefresh) pbjs.addAdUnits(prebidPlacement)

    pbjs.requestBids({
      adUnitCodes: [placementId],
      bidsBackHandler(_bids: BackBidsResponses, timedOut: boolean) {
        if (isRefresh) googleSlot?.setTargeting('ad_refresh', 'true')

        return onBidderBack(
          Bidder.Prebid,
          placementId,
          timedOut ? BidderState.TimedOut : BidderState.Fetched,
        )
      },
      timeout: ADS_BIDDER_TIMEOUT,
    })
  })
}

export function areAllBidsBack(
  placementId: string,
  individualRequestManager: Record<string, RequestManager>,
) {
  const bidders = Object.values(Bidder)
  const backStates = [
    BidderState.Fetched,
    BidderState.TimedOut,
    BidderState.BadSizes,
    BidderState.NoBids,
  ]
  const placementRequestManager = individualRequestManager[placementId]

  if (!placementRequestManager) return false

  return (
    bidders
      .map(bidder => placementRequestManager[bidder])
      .filter(bidderState => backStates.includes(bidderState)).length === bidders.length
  )
}

export function isAdVisible(node?: HTMLElement) {
  if (!node) return false

  const boundaries = node.getBoundingClientRect()

  if (!boundaries) return false

  const offset = boundaries.height * AD_VISIBILITY_RATIO
  const isVisible = window.innerHeight > boundaries.top + offset && boundaries.bottom > offset

  return isVisible
}

export function createNewAdPlacement({
  id,
  placementConfig,
  node,
  isRefreshEnabled = false,
  isManuallyRendered = false,
  isManuallyRefreshed = false,
}: CreatePlacementProps): RtbAdPlacement {
  return {
    code: id,
    adUnitPath: getAdUnitPath(placementConfig),
    adUnitName: getAdUnitName(placementConfig),
    shape: placementConfig.shape,
    sizes: placementConfig.sizes,
    bids: placementConfig.bids,
    dfpCode: placementConfig.dfpCode,
    dfpAccountId: placementConfig.dfpAccountId,
    activeDuration: 0,
    isRefreshEnabled,
    isManuallyRendered,
    isManuallyRefreshed,
    isOutOfPageAd: false,
    node,
  }
}
