'use client'

import { createContext } from 'react'

import { UiState } from '@marketplace-web/shared/ui-helpers'
import { HttpStatus } from 'data/api/response-codes'
import { TransactionModel } from 'types/models'

import { Conversation } from 'types/models/conversation'

import {
  CreateItemConversationThreadArgs,
  GetConversationRequestArgs,
  TranslateConversationArgs,
  SendMessageUiState,
} from './ConversationContext.types'

export type ConversationContextType = {
  conversation: Conversation | undefined
  conversationUiState: UiState
  conversationRequestStatus: HttpStatus | null
  getConversationRequest: ({ conversationId, fromPostReply }: GetConversationRequestArgs) => void
  setConversation: (conversation: Conversation) => void
  setTransaction: (transaction: TransactionModel) => void
  conversationError: string | null
  clearConversation: () => void
  translateConversationRequest: (args: TranslateConversationArgs) => void
  transactionUiState: UiState
  transaction: TransactionModel | null
  createItemConversationThreadRequest: (args: CreateItemConversationThreadArgs) => void
  sendMessageUi: SendMessageUiState
  setSendMessageUi: (uiState: SendMessageUiState) => void
  isFirstTimeListerEducationDialogVisible: boolean
  setIsFirstTimeListerEducationDialogVisible: (isVisible: boolean) => void
}

export const ConversationContext = createContext<ConversationContextType | null>(null)

export default ConversationContext
